import 'lazysizes'
import '@dotlottie/player-component'

import ConsentController from './consent-controller'
import TrackingController from './tracking-controller'

import './deal-link-personalisation'

console.log('desktop critical js loaded')

const app: HTMLElement | null = document.getElementById('app')
const els: NodeListOf<HTMLDivElement> =
  document.querySelectorAll('div[data-consent]')

window.onload = function () {
  new TrackingController(app).connect()
  els.forEach((el) => new ConsentController(el).connect())
}

/** Extra functionality to load dottie player onto desktop */
document
  .querySelectorAll<HTMLVideoElement>('dotlottie-player')
  .forEach((player) => {
    const src = player.getAttribute('data-src')
    if (!src) return
    player.src = src
  })

console.log('critical js loaded')
