import { v4 as uuid } from 'uuid'

const uuidValue = uuid()

document.querySelectorAll<HTMLAnchorElement>('[data-personalise-link]').forEach((el) => {
  const url = el.href

  // stitch in uuid parameters
  const urlParams = new URLSearchParams(url.split('?')[1])

  urlParams.set('comparison_id', uuidValue)
  urlParams.set('cid', uuidValue)
  urlParams.set('llid', uuidValue)

  urlParams.set('page', '/')

  const urlParamsString = urlParams.toString()
  const urlSplit = url.split('?')
  const dealUrl = `${urlSplit[0]}?${urlParamsString}`

  el.href = dealUrl
})
