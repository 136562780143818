import { updateConsents } from '@uswitch/rvuconsent'

enum Icon {
  Success = '<svg style="margin-bottom: -10px" stroke="#38E269" fill="#38E269" stroke-width="0" viewBox="0 0 29 28" height="1.5em" width="1.5em"><path d="M13.073 19.151l.36.365.355-.369 8.104-8.4.339-.351-.344-.348-1.383-1.4-.356-.36-.355.36-6.463 6.54-3.104-3.14-.355-.36-.356.36-1.383 1.4-.348.352.348.351 4.94 5zM1.477 14C1.477 6.57 7.484.5 14.812.5 22.14.5 28.147 6.57 28.147 14S22.14 27.5 14.812 27.5C7.484 27.5 1.477 21.43 1.477 14z"></path></svg>',
  Error = '<svg style="margin-bottom: -10px" stroke="#FF644F" fill="#FF644F" stroke-width="0" viewBox="0 0 32 32" height="1.5em" width="1.5em" ><path d="M18.4945 10.0742L18.5806 9.5H18H14H13.4L13.5082 10.0902L14.6082 16.0902L14.6833 16.5H15.1H17.1H17.5306L17.5944 16.0742L18.4945 10.0742ZM2.5 16C2.5 8.57614 8.57614 2.5 16 2.5C23.4239 2.5 29.5 8.57614 29.5 16C29.5 23.4239 23.4239 29.5 16 29.5C8.57614 29.5 2.5 23.4239 2.5 16ZM13.5 20C13.5 21.3761 14.6239 22.5 16 22.5C17.3761 22.5 18.5 21.3761 18.5 20C18.5 18.6239 17.3761 17.5 16 17.5C14.6239 17.5 13.5 18.6239 13.5 20Z"></path></svg>',
}

class ConsentController {
  private consentContainer: Element
  private consentForm: Element | undefined | null
  private consentInput: Element | undefined | null
  private consentBox: Element | undefined | null

  constructor(el: Element) {
    this.consentContainer = el
    this.consentForm = el.nextElementSibling?.querySelector(
      'form[name="uconsent-form"]'
    )

    if (!this.consentForm) return
    this.consentInput = this.consentForm.querySelector('input')
    this.consentBox = this.consentForm.querySelector('div')
  }

  onSubmit(e: Event) {
    e.preventDefault()
    e.stopPropagation()

    updateConsents({
      email: (this.consentInput as HTMLInputElement)?.value,
      consents: [
        {
          status: true,
          name: this.consentContainer.getAttribute('data-consent') || '',
          source: this.consentForm?.getAttribute('data-consent-source') || '',
          sourceUrl: window.location.href,
        },
      ],
    })
      .then(this.onSuccess.bind(this))
      .catch(this.onError.bind(this))
  }

  clear(): void {
    if (!this.consentBox) return
    this.consentBox.innerHTML = ''
  }

  create(text: string, icon: Icon): HTMLElement {
    const p = document.createElement('p')
    const iconSpan = document.createElement('span')
    const textSpan = document.createElement('span')

    iconSpan.innerHTML = icon
    iconSpan.setAttribute('role', 'img')
    iconSpan.style.paddingRight = '8px'

    textSpan.innerText = text
    textSpan.style.verticalAlign = iconSpan.style.verticalAlign = 'middle'

    p.style.fontSize = '1.6em'
    p.style.margin = '12px 0'
    p.appendChild(iconSpan)
    p.appendChild(textSpan)

    return p
  }

  onSuccess() {
    if (!this.consentBox) return
    this.clear()
    this.consentBox.appendChild(
      this.create('Thank you for subscribing', Icon.Success)
    )
  }

  onError() {
    if (!this.consentBox) return
    this.clear()
    this.consentBox.appendChild(this.create('Something went wrong', Icon.Error))
  }

  connect() {
    ;(this.consentForm as HTMLFormElement).onsubmit = this.onSubmit.bind(this)
  }
}

export default ConsentController
