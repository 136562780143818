declare global {
  interface Window {
    __experiences__: {
      [key: string]: {
        recording: Boolean
        cohort: number
        iteration: string
        value: string
      }
    }
    nerdalytics: {
      page: any
      track: any
    }
  }
}

interface ElementClickedEvent {
  element_name: string
  element_text: string
  element_type: string
}

interface ExperimentLoadedEvent {
  experiment_id: string
  iteration_id: string
  variation_id: string
  experiment_name?: string
  variation_name?: string
  iteration_name?: string
}

interface EVTrackingProps {
  component_category: string
  component_id: string
  component_name: string
  contentful_name: string
}

type EventProperties = ExperimentLoadedEvent | ElementClickedEvent

enum EventType {
  ElementClicked = 'Element Clicked',
  ExperimentLoaded = 'Experiment Loaded',
  PageView = 'page',
}

class TrackingController {
  private app: HTMLElement | null

  track(
    type: EventType.ExperimentLoaded,
    props: ExperimentLoadedEvent
  ): void
  track(type: EventType.PageView): void
  track(type: EventType.ElementClicked, props: ElementClickedEvent): void

  track(type: EventType, props?: EventProperties): void {
    if (type === EventType.PageView) window.nerdalytics.page()
    if (type !== EventType.PageView) window.nerdalytics.track(type, props)
  }

  constructor(el: HTMLElement | null) {
    this.app = el

    this.track(EventType.PageView)
    Object.entries(window.__experiences__)
      .filter(([, exp]) => exp.recording)
      .forEach(([key, exp]) => {
        this.track(EventType.ExperimentLoaded, {
          experiment_id: key,
          iteration_id: `${exp.iteration}`,
          variation_id: `${exp.cohort}`,
          variation_name: exp.value,
        })
      })
  }

  elementClicked(e: Event) {
    const target = e.currentTarget as HTMLAnchorElement

    const trackingVal: string = target?.href || ''
    const trackingAttr: string =
      target
        ?.closest('[data-component-tracking]')
        ?.getAttribute('data-component-tracking') || ''

    if (!trackingAttr) return
    const trackingProps: EVTrackingProps = JSON.parse(trackingAttr)

    this.track(EventType.ElementClicked, {
      element_name: trackingProps.contentful_name,
      element_text: trackingVal,
      element_type: 'link',
    })
  }

  connect() {
    if (!this.app) return console.error('ERROR: #app not found')
    const anchors: NodeListOf<HTMLAnchorElement> =
      this.app.querySelectorAll('a')

    anchors.forEach((a: HTMLAnchorElement) => {
      a.onclick = this.elementClicked.bind(this)
    })
  }
}

export default TrackingController
